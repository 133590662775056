import { BackgroundThemeVariants, ButtonVariantsRedesign, CTASection, CTASectionProps } from '@dtk/ui-components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & CTASectionProps;
}

const CTASectionBlok = ({ blok }: BlokProps) => {
  blok.backgroundTheme = (blok.immoxBackgroundTheme as BackgroundThemeVariants) || 'white';
  blok.primaryButtonTheme = (blok.immoxPrimaryButtonTheme as ButtonVariantsRedesign) || 'pastelBlue';

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="cta-section">
      <CTASection {...blok} />
    </div>
  );
};

export default CTASectionBlok;
