import classNames from 'classnames';

export type PillSizesPreset = 'xs' | 's' | 'm' | 'l' | 'xl';
export type PillColorPreset = 'cyan' | 'gold' | 'red';
export type FontWeightPreset = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export interface StatusPillProps {
  size?: PillSizesPreset;
  className?: string;
  leadText?: string;
  text: string;
  variant?: PillColorPreset;
  fontBoldLeadText?: FontWeightPreset;
  fontBoldText?: FontWeightPreset;
}

const BGColors: Record<PillColorPreset, string> = {
  cyan: 'text-navy bg-cyan-300',
  gold: 'text-white bg-gold',
  red: 'text-white bg-red',
};

const Size: Record<PillSizesPreset, string> = {
  xs: 'text-xs px-3 py-0.5',
  s: 'text-sm px-3 py-0.5',
  m: 'text-base px-3 py-0.5',
  l: 'text-lg px-3 py-0.5',
  xl: 'text-xl px-3 py-0.5',
};

const FontWeight: Record<FontWeightPreset, string> = {
  100: 'font-thin',
  200: 'font-extralight',
  300: 'font-light',
  400: 'font-normal',
  500: 'font-medium',
  600: 'font-semibold',
  700: 'font-bold',
  800: 'font-extrabold',
  900: 'font-black',
};

export const StatusPill = ({
  leadText = '',
  text,
  fontBoldText = '600',
  variant = 'gold',
  fontBoldLeadText = '300',
  size = 's',
  className = '',
  ...restProbs
}: StatusPillProps) => {
  const pillClassName = classNames('inline-flex leading-0 rounded-full', Size[size], BGColors[variant], className);

  const leadTextpillClassName = classNames('mr-1 font-source-sans-pro', FontWeight[fontBoldLeadText]);
  const textTextpillClassName = classNames('font-source-sans-pro-bold', FontWeight[fontBoldText]);

  return (
    <div>
      <span className={pillClassName}>
        <p className={leadTextpillClassName}>{leadText}</p>
        <p className={textTextpillClassName}>{text}</p>
      </span>
    </div>
  );
};
//export default StatusPill;
