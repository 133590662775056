import {
  BackgroundThemeVariants,
  ButtonVariantsRedesign,
  TwoColumnSection,
  TwoColumnSectionProps,
} from '@dtk/ui-components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & TwoColumnSectionProps;
}

const TwoColumnSectionBlok = ({ blok }: BlokProps) => {
  blok.theme = blok.immoxTheme as BackgroundThemeVariants;
  blok.primaryButtonColor = (blok.immoxPrimaryButtonColor as ButtonVariantsRedesign) || 'pastelBlue';
  blok.secondaryButtonColor = blok.immoxSecondaryButtonColor as ButtonVariantsRedesign;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid}>
      <TwoColumnSection {...blok} />
    </div>
  );
};

export default TwoColumnSectionBlok;
