import { BackgroundThemeVariants, ThankYouSection, ThankYouSectionProps } from '@dtk/ui-components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & ThankYouSectionProps;
}

const ThankYouSectionBlok = ({ blok }: BlokProps) => {
  blok.backgroundTheme = (blok.immoxBackgroundTheme as BackgroundThemeVariants) || 'gold-creme';
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="thank-you-section">
      <ThankYouSection {...blok} />
    </div>
  );
};

export default ThankYouSectionBlok;
