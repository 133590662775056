import { BackgroundThemeVariants, ContactPerson, ContactPersonDetailProps } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

const ContactPersonBlok = ({ blok }) => {
  const chosenTheme = blok.immoxTheme ? blok.immoxTheme : 'white';
  const linearGradientBackgroundColor = new Map<BackgroundThemeVariants, string>([
    ['gray-stoneGray', 'linear-gradient-gray50-to-stone-gray'],
    ['blue-pastelBlue', 'linear-gradient-gray50-to-pastel-blue'],
    ['gold-creme', 'linear-gradient-gray50-to-gold-creme'],
    ['khaki', 'linear-gradient-gray50-to-khaki'],
    ['navy', 'linear-gradient-gray50-to-navy'],
  ]);
  const chosenLinearGradientColor =
    blok.theme === 'white' ? 'bg-white' : linearGradientBackgroundColor.get(chosenTheme);

  const contact: ContactPersonDetailProps = {
    firstname: blok.firstname,
    lastname: blok.lastname,
    eMail: blok.email,
    phone: blok.phone,
    position: blok.position,
    pictureUrl: blok.portrait_image?.filename,
    pictureAltName: blok.portrait_image?.alt,
    pictureFocus: blok.portrait_image?.focus,
    theme: chosenTheme,
    linearGradientBackgroundColor: chosenLinearGradientColor,
    primaryButtonColor: blok.immoxButtonColors || 'pastelBlue',
  };

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="contact-person">
      <ContactPerson contact={contact} />
    </div>
  );
};

export default ContactPersonBlok;
