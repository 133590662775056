import { Navigation } from '@dtk/public-website-ui-components';
import React from 'react';
import { storyblokEditable } from '@storyblok/react';

const NavigationBlok = ({ blok }) => {
  blok.alternativeTopLineColor = 'khaki';

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="navigation" id="web-navigation">
      <Navigation {...blok} />
    </div>
  );
};

export default NavigationBlok;
