import { ContactRequestForm, ContactRequestFormProps } from '@dtk/public-website-ui-components';
import React from 'react';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & ContactRequestFormProps;
}

const ContactRequestFormBlok = ({ blok }: BlokProps) => {
  blok.pageSlug = '/immox-team';
  blok.companyName = 'ImmoX GmbH';

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="contact-request-form">
      <ContactRequestForm {...blok} />
    </div>
  );
};

export default ContactRequestFormBlok;
