import { storyblokEditable } from '@storyblok/react';
import { SmallFooter } from '@dtk/ui-components';

const SmallFooterBlok = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="small-footer">
      <SmallFooter {...blok} />
    </div>
  );
};

export default SmallFooterBlok;
