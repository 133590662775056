import classNames from 'classnames';
import { render } from 'storyblok-rich-text-react-renderer';
import { blokResolvers, defaultBlockResolver, markResolvers, nodeResolvers } from '@dtk/ui-components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & StandardCardContainerProps;
}

interface StandardCardContainerProps {
  _uid: string;
  content: unknown;
}

const RichTextBlok = ({ blok }: BlokProps) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="rich-text">
      <div
        className={classNames(
          'richtext-editor-styles my-8 mx-auto px-4',
          'sm:px-6 md:max-w-xl lg:max-w-2xl lg:px-8 xl:max-w-[50rem]'
        )}
      >
        {render(blok.content, {
          defaultBlokResolver: defaultBlockResolver,
          blokResolvers: blokResolvers,
          nodeResolvers: nodeResolvers,
          markResolvers: markResolvers,
        })}
      </div>
    </div>
  );
};

export default RichTextBlok;
