import { HeaderText } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

const HeaderTextBlok = ({ blok }) => {
  blok.theme = blok.immoxTheme;
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="header-text">
      <HeaderText key={blok._uid} {...blok} />
    </div>
  );
};

export default HeaderTextBlok;
