import classNames from 'classnames';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

const Page = ({ blok }) => {
  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="page">
      {blok?.body?.map((nestedBlok) => (
        <div
          key={nestedBlok._uid}
          className={classNames(
            nestedBlok.reference?.slug?.includes('navigation') || nestedBlok.component === 'Navigation'
              ? 'sticky top-0 z-50'
              : ''
          )}
        >
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  );
};

export default Page;
