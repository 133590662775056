import CardWithImageTextElementsAndButtonBlok from './02_composite/CardWithImageTextElementsAndButtonBlok';
import ContactPersonBlok from './02_composite/ContactPersonBlok';
import ContactRequestFormBlok from './02_composite/ContactRequestFormBlok';
import CTASectionBlok from './02_composite/CTASectionBlok';
import FeatureSectionBlok from './02_composite/FeatureSectionBlok';
import FeatureSectionListBlok from './02_composite/FeatureSectionList/FeatureSectionListBlok';
import FeatureSectionListItemBlok from './02_composite/FeatureSectionList/FeatureSectionListItemBlok';
import GlobalReferenceBlok from './GlobalReferenceBlok';
import HeaderTextBlok from './01_elements/HeaderTextBlok';
import NavigationBlok from './02_composite/NavigationBlok';
import Page from './Page';
import PublicHeroSectionBlok from './02_composite/PublicHeroSectionBlok';
import RichTextBlok from './02_composite/RichTextBlok';
import SmallFooterBlok from './02_composite/SmallFooterBlok';
import StandardCardContainerBlok from './01_elements/StandardCardContainerBlok';
import TeamSectionBlok from './02_composite/TeamSectionBlok';
import TwoColumnSectionBlok from './02_composite/TwoColumnSectionBlok';
import ThankYouSectionBlok from './02_composite/ThankYouSectionBlok';

const WrapperComponents = {
  global_reference: GlobalReferenceBlok,
  page: Page,
};

export const StoryblokComponents = {
  ...WrapperComponents,
  CardWithImageTextElementsAndButton: CardWithImageTextElementsAndButtonBlok,
  ContactPerson: ContactPersonBlok,
  ContactRequestForm: ContactRequestFormBlok,
  CTASection: CTASectionBlok,
  FeatureSection: FeatureSectionBlok,
  HeaderText: HeaderTextBlok,
  Navigation: NavigationBlok,
  PublicHeroSection: PublicHeroSectionBlok,
  RichTextBlok: RichTextBlok,
  SmallFooter: SmallFooterBlok,
  StandardCardContainer: StandardCardContainerBlok,
  TeamSection: TeamSectionBlok,
  ThankYouSection: ThankYouSectionBlok,
  TwoColumnSection: TwoColumnSectionBlok,
  WhatWeAre: FeatureSectionListBlok,
  WhatWeAreTextItem: FeatureSectionListItemBlok,
};
