import {
  BackgroundThemeVariants,
  ButtonVariantsRedesign,
  FeatureSection,
  FeatureSectionProps,
} from '@dtk/ui-components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & FeatureSectionProps;
}

const FeatureSectionBlok = ({ blok }: BlokProps) => {
  blok.theme = blok.immoxTheme as BackgroundThemeVariants;
  blok.primaryButtonColor = (blok.immoxButtonColor as ButtonVariantsRedesign) || 'pastelBlue';
  return (
    <div {...storyblokEditable(blok)} data-test="feature-section">
      <FeatureSection {...blok} />
    </div>
  );
};

export default FeatureSectionBlok;
