import { BackgroundThemeVariants } from '@dtk/ui-components';
import { SbBlokData, StoryblokComponent, storyblokEditable } from '@storyblok/react';

interface BlokProps {
  blok: SbBlokData & StandardCardContainerProps;
}

interface StandardCardContainerProps {
  _uid: string;
  immoxBackgroundColor: BackgroundThemeVariants;
  overline?: string;
  allCards: SbBlokData[];
}

const StandardCardContainerBlok = ({ blok }: BlokProps) => {
  const { _uid, immoxBackgroundColor, overline, allCards } = blok;
  return (
    <div {...storyblokEditable(blok)} key={_uid} data-test="standard-card-container">
      <div className={immoxBackgroundColor || 'bg-white'}>
        <div className="mx-auto max-w-7xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
          {overline && <p className="text-gold-gold mb-10 text-center text-lg uppercase tracking-[.2em]">{overline}</p>}
          <div className="grid grid-cols-4 gap-5 sm:grid-cols-8 lg:grid-cols-12">
            {allCards &&
              allCards.map((nestedBlok: SbBlokData, index: number) => (
                <StoryblokComponent
                  key={nestedBlok._uid}
                  blok={nestedBlok}
                  index={index}
                  numberOfCards={allCards.length}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardCardContainerBlok;
