import './styles/styles.css';
import '@dtk/default-theme';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { apiPlugin, storyblokInit } from '@storyblok/react';

import { StoryblokComponents } from '../components/StoryblokComponents';
import NextAndPrevLinks from '../components/Head/NextAndPrevLinks';
import MetaTags from '../components/Head/MetaTags';
import Layout from '../components/Layout';

storyblokInit({
  accessToken: '3koxfeFUxXuPQrk6kSVJxAtt',
  use: [apiPlugin],
  components: StoryblokComponents,
});

function CustomApp({ Component, pageProps }: AppProps) {
  const { story } = pageProps;

  return (
    <>
      <Head>
        <title key="title">{story?.content?.MetaTags?.title || 'ImmoX'}</title>
        <link
          rel="preload"
          href="/_next/static/media/Inter-Regular.afe848f8.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Head>
      <NextAndPrevLinks story={story} />
      {story?.content?.MetaTags && <MetaTags metaTags={story?.content?.MetaTags} />}
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}

export default CustomApp;
