import { Container, Team } from '@dtk/ui-components';
import { storyblokEditable } from '@storyblok/react';

const TeamSectionBlok = ({ blok }) => {
  const chosenTheme = blok.immoxBackgroundColor;

  return (
    <div {...storyblokEditable(blok)} key={blok._uid} data-test="team-section">
      <Container backgroundColor={chosenTheme}>
        <Team theme={chosenTheme} {...blok} />
      </Container>
    </div>
  );
};

export default TeamSectionBlok;
